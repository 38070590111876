import C4tClient, { HTTP_METHODS } from '../C4tClient';
import URI_CONSTANTS from '../../../constants/uri';
import { getToken } from '../../../utils/httpUtils';

export default {
  getMessages: (quoteId) => new C4tClient()
    .setToken(getToken())
    .setPath(`${URI_CONSTANTS.MESSAGES.GET}/${quoteId}`)
    .setMethod(HTTP_METHODS.GET),
  sendMessage: (quoteId, body) => new C4tClient()
    .setToken(getToken())
    .setPath(`${URI_CONSTANTS.MESSAGES.SEND}/${quoteId}`)
    .setMethod(HTTP_METHODS.POST)
    .setJSONBody(body),
  getUnreadMessageCount: (quoteId) => new C4tClient()
    .setToken(getToken())
    .setPath(`${URI_CONSTANTS.MESSAGES.COUNT}/${quoteId}`)
    .setMethod(HTTP_METHODS.GET),
  markMessagesAsRead: (quoteId) => new C4tClient()
    .setToken(getToken())
    .setPath(`${URI_CONSTANTS.MESSAGES.MARK_READ}/${quoteId}`)
    .setMethod(HTTP_METHODS.POST),
  uploadFile: (quoteId, formData) => new C4tClient()
    .setMethod(HTTP_METHODS.POST)
    .setToken(getToken())
    .setPath(`${URI_CONSTANTS.MESSAGES.UPLOAD_FILE}/${quoteId}`)
    .setBody(formData),
  downloadFile: (quoteId, fileIndex) => new C4tClient()
    .setToken(getToken())
    .setPath(URI_CONSTANTS.MESSAGES.DOWLOAD_FILE(quoteId, fileIndex)),
};
