import C4tClient, { HTTP_METHODS } from '../C4tClient';
import URI_CONSTANTS from '../../../constants/uri';

export default {
  getProducts: () => new C4tClient()
    .setPath(URI_CONSTANTS.PRODUCT.GET)
    .setMethod(HTTP_METHODS.GET),
  getBrands: () => new C4tClient()
    .setPath(URI_CONSTANTS.PRODUCT.BRAND)
    .setMethod(HTTP_METHODS.GET),
  getVersion: () => new C4tClient()
    .setPath(URI_CONSTANTS.PRODUCT.VERSION)
    .setMethod(HTTP_METHODS.GET),
};
