import PropTypes from 'prop-types';
import { parseDay } from '../../../../utils/dateUtils';
import { format } from 'date-fns';
import DATE_FORMATS from '../../../../constants/dateFormat';
import { useGetProductBySkuQuery } from '../../../../store/apiSlice/productSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Box,
  Button,
  Select,
  MenuItem,
  IconButton,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import useIsMobileView from '../../../../hooks/useIsMobileView';
import { ReactComponent as CollaseOpenIcon } from '../../../../assets/svg/collapse_open.svg';
import { ReactComponent as CollaseCloseIcon } from '../../../../assets/svg/collapse_close.svg';
import { buildManualProduct } from '../../../../utils/quoteUtils';
import NonBrandItem from '../../../../components/Form/NonBrandItem';
import { DatePicker } from '@mui/x-date-pickers';
import { flattenBrands } from '../../../../utils/quoteUtils';
import '../../../../css/vendor-form.css';
import ItemQuantityField from '../ItemQuantityField';
import ItemConditionSelect from '../ItemConditionSelect';
import ItemProductImageAndText from '../ItemProductImageAndText';

const AddedItem = (props) => {
  const {
    item,
    brands,
    errors,
    onRemove,
    onQuantityChange,
    onConditionChange,
    onBrandChange,
    onExpirationDateChange,
    onValidate,
  } = props;

  const isMobile = useIsMobileView();

  /** Products */
  const { data: apiItemProduct, isLoading } = useGetProductBySkuQuery(
    item.product_id
  );
  const isManualItem = !item.product_id;
  const itemProduct = useMemo(
    () =>
      isManualItem
        ? buildManualProduct(item.userInput, item.brand)
        : apiItemProduct,
    [apiItemProduct, isManualItem, item.brand, item.userInput]
  );

  /** Condition */
  const hasConditionErrors = errors.hasOwnProperty('condition');
  const handleConditionChange = (event) => {
    const newCondition = event;
    const lineItems = onConditionChange(newCondition);
    onValidate(lineItems);
  };

  /** Brand and preferred brand*/
  const hasBrandErrors = errors.hasOwnProperty('brand');
  const [preferredBrand, setPreferredBrand] = useState(null);

  const defaultBrand = flattenBrands(brands).find(
    (brandItem) => brandItem?.value === item?.brand
  );
  const [openBrandSelect, setOpenBrandSelect] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(defaultBrand ?? '');
  const handleBrandChange = (event) => {
    const newBrand = event.target.value;
    setSelectedBrand(event.target.value);
    const lineItems = onBrandChange(newBrand);
    onValidate(lineItems);
  };

  const flattenedGroupBrands = useMemo(() => {
    if (!brands) return null;
    return flattenBrands(brands);
  }, [brands]);

  // Brand options are filtered based on the preferred brand.
  const brandOptions = useMemo(() => {
    if (!preferredBrand) return flattenedGroupBrands;
    return [
      ...flattenedGroupBrands.filter((b) =>
        (b?.value ?? b).includes(preferredBrand)
      ),
      'Other',
    ];
  }, [flattenedGroupBrands, preferredBrand]);

  /** Expiration date */
  const isExpirationDateMandatory =
    item?.product?.requires_expiration_date ?? false;
  const hasExpirationDateErrors = errors.hasOwnProperty(
    'requires_expiration_date'
  );
  const expirationDate = useMemo(() => {
    let date = null;
    if (item.expirationDate) date = item.expirationDate;
    if (item.boxDate) date = item.boxDate;
    if (item.expiration_date) date = item.expiration_date;
    if (item.box_date) date = item.box_date;
    if (date !== null && !isNaN(date.getTime()))
      return format(
        typeof date === 'string' ? parseDay(date) : date,
        DATE_FORMATS.SLASHED.NUMERIC
      );
    return null;
  }, [item.expirationDate, item.boxDate, item.expiration_date, item.box_date]);

  const handleExpirationDate = (event) => {
    const newExpirationDate = event;
    const newLineItems = onExpirationDateChange(newExpirationDate);
    onValidate(newLineItems);
  };

  const handleDatePickerViewChange = useCallback(
    (view) => {
      if (view !== 'year') return;
      const selectedYear = `${(expirationDate ?? new Date()).getFullYear()}`;
      setTimeout(() => {
        const yearButton = [
          ...document.querySelectorAll('.PrivatePickersYear-yearButton'),
        ].filter((button) => button.innerHTML === selectedYear)[0];
        yearButton.scrollIntoView({ behavior: 'auto' });
      }, 50);
    },
    [expirationDate]
  );

  /** useEffect */
  // The default preferred brand is the product's brand, or HP if the current product brand includes the HP text.
  useEffect(() => {
    if (!isLoading && item.product_id !== null) {
      // If there is no brand selected, set the product's brand as the preferred brand.
      if (selectedBrand === '') {
        const brandIncludesHp = apiItemProduct?.attributes?.brand
          ? apiItemProduct.attributes.brand.includes('HP')
          : false;
        setPreferredBrand(
          brandIncludesHp ? 'HP' : apiItemProduct?.attributes?.brand
        );
      } else {
        // If there is already a brand selected and the selected brand is tbe same as the product's brand, set the preferred brand.
        // For HP products this is checked by assuring that the brand name contains the HP text.
        const isSelectedBrandHp = selectedBrand?.value
          ? selectedBrand.value.includes('HP')
          : false;
        if (
          item.product_id !== null &&
          (selectedBrand?.originalBrand === apiItemProduct?.attributes?.brand ||
            (isSelectedBrandHp && apiItemProduct?.attributes?.brand === 'HP'))
        ) {
          const brandIncludesHp = apiItemProduct?.attributes?.brand
            ? apiItemProduct.attributes.brand.includes('HP')
            : false;
          setPreferredBrand(
            brandIncludesHp ? 'HP' : apiItemProduct?.attributes?.brand
          );
        }
      }
    }
  }, [isLoading, apiItemProduct, item]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Grid
      container
      sx={{
        flexDirection: isMobile ? 'column' : 'row',
        border: '1px solid #C9C9C9',
        borderRadius: '20px',
        fontWeight: 600,
        fontSize: '14px',
        color: '#000000',
        width: isMobile ? '100%' : '100%',
      }}
      mb={2}
    >
      {/** Item text and image */}
      <Box
        className='added-product-first-box'
        sx={{
          display: 'flex',
          borderTopRightRadius: isMobile ? 'unset' : 'inherit',
          borderBottomRightRadius: 'inherit',
          borderBottomLeftRadius: isMobile ? 'inherit' : 'unset',
          minHeight: '100px',
          alignItems: 'center',
          justifyContent: isMobile ? 'space-between' : '',
        }}
      >
        {/** Item image, sku and label */}
        <Box className='added-product-image-and-text'>
          <ItemProductImageAndText
            isLoading={isLoading}
            item={item}
            itemProduct={itemProduct}
            original={item?.original}
          />
        </Box>
        {/** Remove action */}
        <Grid item xs={2} md={1} display="flex" alignItems="center" gap={2}>
          <IconButton onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Box>

      <Box
        className='added-product-form'
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          backgroundColor: '#EAEAEA',
          borderTopRightRadius: isMobile ? 'unset' : 'inherit',
          borderBottomRightRadius: 'inherit',
          borderBottomLeftRadius: isMobile ? 'inherit' : 'unset',
          minHeight: '100px',
          paddingLeft: isMobile ? '2px' : '20px',
          paddingRight: isMobile ? '2px' : '',
          paddingTop: isMobile ? '10px' : '15px !important',
          paddingBottom: isMobile ? '10px' : '',
          flexGrow: isMobile ? 'initial' : '1',
        }}
      >
        {/** Condition selector */}
        <Box
          display="flex"
          justifyContent="center"
          marginRight="15px"
          flexDirection={isMobile ? 'row' : 'column'}
          alignItems={isMobile ? 'center' : 'unset'}
          sx={
            isMobile
              ? { justifyContent: 'space-between', flexWrap: 'wrap' }
              : {}
          }
        >
          <Typography
            fontFamily="'Lexend', sans-serif"
            fontSize="15px"
            marginLeft={2}
          >
            Condition
          </Typography>
          <ItemConditionSelect
            value={item.condition}
            onChange={handleConditionChange}
          />
          {isMobile && <div className="expiration-date-subtext-break"></div>}
          {!hasConditionErrors && !isMobile && (
            <Typography
              color="#787878"
              fontFamily="'Lexend', sans-serif"
              fontSize="12px"
              marginLeft={2}
              visibility={'hidden'}
            >
              (required)
            </Typography>
          )}

          {/** Subtext when condition has errors */}
          {hasConditionErrors && (
            <Typography
              color="rgb(211, 47, 47) !important"
              fontFamily="'Lexend', sans-serif"
              fontSize="12px"
              marginLeft={2}
            >
              Required
            </Typography>
          )}
        </Box>

        {isMobile && <hr className="item-field-separator" />}
        {/** Brand selector */}
        <Box
          display="flex"
          justifyContent="center"
          marginRight="15px"
          flexDirection={isMobile ? 'row' : 'column'}
          alignItems={isMobile ? 'center' : 'unset'}
          sx={
            isMobile
              ? { justifyContent: 'space-between', flexWrap: 'wrap' }
              : {}
          }
        >
          <Typography
            fontFamily="'Lexend', sans-serif"
            fontSize="15px"
            marginLeft={2}
          >
            Brand
          </Typography>
          <Select
            className="brand-input"
            value={selectedBrand}
            onChange={handleBrandChange}
            open={openBrandSelect}
            onOpen={() => setOpenBrandSelect(true)}
            onClose={() => setOpenBrandSelect(false)}
            displayEmpty={true}
            renderValue={(value) => (
              <Typography
                fontFamily="'Lexend', sans-serif"
                fontSize="14px"
                color="#939393"
              >
                {value.length === 0 ? 'Select' : value.label}
              </Typography>
            )}
            sx={{
              width: isMobile ? '60%' : '130px',
              maxWidth: isMobile ? '187px' : 'initial',
              minWidth: isMobile ? '187px' : 'initial',
              height: '39px',
              backgroundColor: 'white',
            }}
          >
            <Box
              sx={{
                textAlign: 'end',
                position: 'sticky',
                top: '10px',
                zIndex: 3,
              }}
            >
              <Button
                sx={{
                  backgroundColor: 'transparent',
                  color: 'black',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'black',
                    boxShadow: 'none',
                  },
                }}
                color="success"
                variant="contained"
                onClick={() => setOpenBrandSelect(false)}
              >
                X
              </Button>
            </Box>
            {brandOptions.map((brand) =>
              typeof brand === 'string' ? (
                <NonBrandItem
                  key={brand}
                  brandName={brand}
                  setPreferredBrand={setPreferredBrand}
                />
              ) : (
                <MenuItem
                  value={brand}
                  key={`option_${brand.value}`}
                  sx={{ ml: 1 }}
                >
                  {brand.label}
                </MenuItem>
              )
            )}
          </Select>
          {isMobile && <div className="expiration-date-subtext-break"></div>}
          {!hasBrandErrors && !isMobile && (
            <Typography
              color="#787878"
              fontFamily="'Lexend', sans-serif"
              fontSize="12px"
              marginLeft={2}
              visibility={'hidden'}
            >
              (required)
            </Typography>
          )}

          {/** Subtext when condition has errors */}
          {hasBrandErrors && (
            <Typography
              color="rgb(211, 47, 47) !important"
              fontFamily="'Lexend', sans-serif"
              fontSize="12px"
              marginLeft={2}
            >
              Required
            </Typography>
          )}
        </Box>

        {/** Quantity selector */}
        {isMobile && <hr className="item-field-separator" />}
        <Box
          display="flex"
          justifyContent="center"
          marginRight="15px"
          flexDirection={isMobile ? 'row' : 'column'}
          alignItems={isMobile ? 'center' : 'unset'}
          sx={isMobile ? { justifyContent: 'space-between' } : {}}
        >
          <Typography
            fontFamily="'Lexend', sans-serif"
            fontSize="15px"
            marginLeft={2}
          >
            Quantity
          </Typography>
          <ItemQuantityField
            value={item.quantity}
            onChange={onQuantityChange}
          />
          {!isMobile && (
            <Typography
              color="#787878"
              fontFamily="'Lexend', sans-serif"
              fontSize="12px"
              marginLeft={2}
              visibility="hidden"
            >
              (required)
            </Typography>
          )}
        </Box>

        {/** Expiration date selector */}
        {isMobile && <hr className="item-field-separator" />}
        <Box
          display="flex"
          justifyContent="center"
          marginRight="10px"
          flexDirection={isMobile ? 'row' : 'column'}
          alignItems={isMobile ? 'center' : 'unset'}
          sx={
            isMobile
              ? { justifyContent: 'space-between', flexWrap: 'wrap' }
              : {}
          }
        >
          <Typography
            fontFamily="'Lexend', sans-serif"
            fontSize="15px"
            marginLeft={2}
          >
            Expiration{' '}
            {!isExpirationDateMandatory && (
              <span
                className="item-condition-optional"
                style={{ display: isMobile ? 'block' : 'inline' }}
              >
                (optional)
              </span>
            )}
          </Typography>
          <DatePicker
            className="formDatePicker"
            id="expirationDateInput"
            value={expirationDate}
            onChange={handleExpirationDate}
            onViewChange={handleDatePickerViewChange}
            renderInput={(params) => <TextField {...params} />}
          />
          {isMobile && <div className="expiration-date-subtext-break"></div>}
          {/** Subtext when expiration date has no errors */}
          {!hasExpirationDateErrors && (
            <Typography
              color="#787878"
              fontFamily="'Lexend', sans-serif"
              fontSize="12px"
              marginLeft={2}
              visibility="hidden"
            >
              (optional)
            </Typography>
          )}

          {/** Subtext when expiration date has errors */}
          {hasExpirationDateErrors && (
            <Typography
              color="rgb(211, 47, 47) !important"
              fontFamily="'Lexend', sans-serif"
              fontSize="12px"
              marginLeft={2}
            >
              Expiration date is required
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

const CollapsableButton = ({ open, onClick }) => (
  <IconButton onClick={onClick}>
    {open ? <CollaseCloseIcon /> : <CollaseOpenIcon />}
  </IconButton>
);

export default AddedItem;

AddedItem.propTypes = {
  item: PropTypes.object.isRequired,
  brands: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onRemove: PropTypes.func,
  onQuantityChange: PropTypes.func,
  onConditionChange: PropTypes.func,
  onBrandChange: PropTypes.func,
  onExpirationDateChange: PropTypes.func,
  onValidate: PropTypes.func,
};
CollapsableButton.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
