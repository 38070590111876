import Errors from '../constants/errors';
import toast from 'react-hot-toast';

const checkResponseStatus = async (response) => {
  let errorBody = null;
  const { status } = response;
  if (status >= 400) errorBody = await response.json();
  if (status >= 500) throw Errors.API_ERRORS.SERVER_ERROR(status, errorBody?.message);
  if (status === 404) throw Errors.API_ERRORS.NOT_FOUND(errorBody?.message);
  if (status === 403) throw Errors.API_ERRORS.FORBIDDEN(errorBody?.message);
  if (status === 401) throw Errors.API_ERRORS.UNAUTHORIZED(errorBody?.message);
  if (status === 400) throw Errors.API_ERRORS.BAD_REQUEST(errorBody?.message);
  if (status >= 400) throw Errors.API_ERRORS.UNKNOWN_ERROR(status, errorBody?.message);
  return response;
};

const handleUploadError = (error) => {
  switch (error.status) {
    case 413:
      toast.error('The file is too big, files must be smaller than 15MB');
      break;
    default:
      console.error(`Unrecognised status code for http error. Status: ${error.status}`);
      console.error(error);
  }
};

export { checkResponseStatus, handleUploadError };
