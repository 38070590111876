import C4tClient, { HTTP_METHODS } from '../C4tClient';
import URI_CONSTANTS from '../../../constants/uri';
import { getToken } from '../../../utils/httpUtils';

export default {
  getQuote: (id) => new C4tClient().setPath(`${URI_CONSTANTS.QUOTE.GET}/${id}`).setToken(getToken()),
  getQuotes: () => new C4tClient().setPath(URI_CONSTANTS.QUOTE.GET).setToken(getToken()),
  postQuote: (body) => new C4tClient()
    .setPath(`${URI_CONSTANTS.QUOTE.CREATE}`)
    .setJSONBody(body)
    .setMethod(HTTP_METHODS.POST)
    .setToken(getToken()),
  updateQuoteItemPictures: (id, body) => new C4tClient()
    .setPath(`${URI_CONSTANTS.QUOTE.UPDATE_PICTURES}/${id}`)
    .setToken(getToken())
    .setJSONBody(body)
    .setMethod(HTTP_METHODS.PUT),
  uploadQuoteImage: (id, file) => {
    const body = new FormData();
    body.append('image', file, file.name);
    return new C4tClient().setPath(`${URI_CONSTANTS.QUOTE.UPDATE_PICTURES}/${id}`).setMethod(HTTP_METHODS.POST).setToken(getToken())
      .setBody(body);
  },
  sendAuthlessImageUploadText: (id, phone) => new C4tClient().setToken(getToken()).setPath(`${URI_CONSTANTS.QUOTE.UPDATE_PICTURES}/${id}/phone?phone=${phone}`),
  deleteQuoteImage: (id, imageHandle) => new C4tClient().setPath(`${URI_CONSTANTS.QUOTE.UPDATE_PICTURES}/${id}?handle=${imageHandle}`).setMethod(HTTP_METHODS.DELETE).setToken(getToken()),
  setPaymentMethod: (quoteId, paymentMethod, name, email, sendTo) => new C4tClient()
    .setPath(URI_CONSTANTS.QUOTE.SET_PAYMENT_METHOD(quoteId))
    .setMethod(HTTP_METHODS.POST)
    .setJSONBody({ payment_type: paymentMethod, name, email, send_to: sendTo })
    .setToken(getToken()),
  getPayment: (quoteId) => new C4tClient()
    .setPath(URI_CONSTANTS.QUOTE.SET_PAYMENT_METHOD(quoteId))
    .setMethod(HTTP_METHODS.GET)
    .setToken(getToken()),
  setShipping: (quoteId, labelAmount, address, weight) => new C4tClient()
    .setPath(URI_CONSTANTS.QUOTE.SET_SHIPPING(quoteId))
    .setMethod(HTTP_METHODS.POST)
    .setJSONBody({ label_amount: labelAmount, address, weight })
    .setToken(getToken()),
  setTracking: (quoteId, trackingNumber) => new C4tClient()
    .setPath(URI_CONSTANTS.QUOTE.SET_TRACKING(quoteId))
    .setMethod(HTTP_METHODS.POST)
    .setJSONBody({ tracking_number: trackingNumber })
    .setToken(getToken()),
  getLabels: (quoteId) => new C4tClient().setPath(URI_CONSTANTS.QUOTE.GET_LABELS(quoteId)).setToken(getToken()),
};
