import C4tClient, { HTTP_METHODS } from '../C4tClient';
import URI_CONSTANTS from '../../../constants/uri';
import { getToken } from '../../../utils/httpUtils';

// eslint-disable-next-line no-restricted-globals
const getScreenSize = () => `${screen.width}x${screen.height}`;

export default {
  login: (email, password) => new C4tClient()
    .setPath(URI_CONSTANTS.AUTH.LOGIN)
    .setMethod(HTTP_METHODS.POST)
    .setJSONBody({
      email,
      password,
    }),
  me: (token = getToken()) => new C4tClient()
    .setPath(
      `${URI_CONSTANTS.PERSONAL_INFORMATION.GET}?screen=${getScreenSize()}`
    )
    .setToken(token)
    .setMethod(HTTP_METHODS.GET),
  signUp: (firstName, lastName, email, password, phone, address, company) => new C4tClient()
    .setPath(URI_CONSTANTS.AUTH.SIGN_UP)
    .setMethod(HTTP_METHODS.POST)
    .setJSONBody({
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      phone,
      address,
      company,
    }),
  changePassword: ({ old_password: oldPassword, new_password: newPassword }) => new C4tClient()
    .setToken(getToken())
    .setPath(URI_CONSTANTS.AUTH.CHANGE_PASSWORD)
    .setMethod(HTTP_METHODS.POST)
    .setJSONBody({
      old_password: oldPassword,
      new_password: newPassword,
    }),
  updatePersonalInformation: ({
    first_name: firstName,
    last_name: lastName,
    mobile,
    company,
    address,
    state,
    city,
    zip,
    phone,
  }) => new C4tClient()
    .setPath(URI_CONSTANTS.PERSONAL_INFORMATION.GET)
    .setToken(getToken())
    .setMethod(HTTP_METHODS.PUT)
    .setJSONBody({
      first_name: firstName,
      last_name: lastName,
      mobile,
      company,
      address,
      state,
      city,
      zip,
      phone,
    }),
  recoverPassword: ({ email }) => new C4tClient()
    .setPath(URI_CONSTANTS.AUTH.RECOVER_EMAIL)
    .setMethod(HTTP_METHODS.POST)
    .setJSONBody({
      recovery_email: email,
    }),
  resetPassword: ({ code, email, password }) => new C4tClient()
    .setPath(URI_CONSTANTS.AUTH.RESET_EMAIL)
    .setMethod(HTTP_METHODS.POST)
    .setJSONBody({
      recovery_email: email,
      recovery_token: code,
      password,
    }),
  getNotifications: (status) => new C4tClient()
    .setToken(getToken())
    .setPath(URI_CONSTANTS.NOTIFICATIONS.GET)
    .setMethod(HTTP_METHODS.GET)
    .addParameter('status', status),
  getNotificationsCount: () => new C4tClient()
    .setToken(getToken())
    .setPath(URI_CONSTANTS.NOTIFICATIONS.COUNT)
    .setMethod(HTTP_METHODS.GET),
  markNotificationsAsRead: (notificationIds, status) => new C4tClient()
    .setToken(getToken())
    .setPath(URI_CONSTANTS.NOTIFICATIONS.MARK_READ)
    .setMethod(HTTP_METHODS.POST)
    .setJSONBody({
      notification_ids: notificationIds,
    })
    .addParameter('status', status),
  sendPhoneVerificationCode: (phone) => new C4tClient()
    .setToken(getToken())
    .setPath(`${URI_CONSTANTS.VERIFICATION.SEND_SMS_CODE}?phone=${phone}`),
  checkVerificationCode: (code) => new C4tClient()
    .setToken(getToken())
    .setPath(`${URI_CONSTANTS.VERIFICATION.VERIFY_SMS_CODE}?code=${code}`),
};
