import TagManager from 'react-gtm-module';

const sendGTMEvent = (e) => {
  // Send  event to Google
  TagManager.dataLayer({
    dataLayer: {
      event: e,
    },
  });
};

export { sendGTMEvent };
