import account from './calls/account';
import quote from './calls/quote';
import product from './calls/product';
import offer from './calls/offer';
import messages from './calls/messages';
import publicApi from './calls/public';
import authlessApi from './calls/authless';

export default {
  product,
  quote,
  account,
  offer,
  messages,
  publicApi,
  authlessApi
};
