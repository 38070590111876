import C4tClient, { HTTP_METHODS } from '../C4tClient';
import URI_CONSTANTS from '../../../constants/uri';
import { getToken } from '../../../utils/httpUtils';

const updateOfferStatus = (url) => new C4tClient().setPath(url).setMethod(HTTP_METHODS.GET).setToken(getToken());

export default {
  accept: (quoteId) => updateOfferStatus(`${URI_CONSTANTS.OFFER.ACCEPT}/${quoteId}`),
  reject: (quoteId) => updateOfferStatus(`${URI_CONSTANTS.OFFER.REJECT}/${quoteId}`),
  counter: (quoteId, body) => new C4tClient()
    .setPath(`${URI_CONSTANTS.OFFER.COUNTER}/${quoteId}`)
    .setMethod(HTTP_METHODS.POST)
    .setJSONBody(body)
    .setToken(getToken()),
};
