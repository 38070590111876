import URI_CONSTANTS from '../../../constants/uri';
import C4tClient, { HTTP_METHODS } from '../C4tClient';

const buildClientWithHashAuthentication = (hash) => new C4tClient().addHeader('Authorization', hash);

export default {
  getImages: (hash, quoteId) => buildClientWithHashAuthentication(hash)
    .setPath(URI_CONSTANTS.AUTHLESS.IMAGES(quoteId))
    .setMethod(HTTP_METHODS.GET),
  postImage: (hash, quoteId, file) => {
    const body = new FormData();
    body.append('image', file, file.name);
    return buildClientWithHashAuthentication(hash)
      .setPath(URI_CONSTANTS.AUTHLESS.IMAGES(quoteId))
      .setBody(body)
      .setMethod(HTTP_METHODS.POST);
  },
  deleteImage: (hash, quoteId, handle) => buildClientWithHashAuthentication(hash)
    .setPath(`${URI_CONSTANTS.AUTHLESS.IMAGES(quoteId)}?handle=${handle}`)
    .setMethod(HTTP_METHODS.DELETE)
};
