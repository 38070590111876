import env from '../../constants/env';
import { checkResponseStatus } from '../../utils/errorUtils';
import { formatToken } from '../../utils/httpUtils';

const HTTP_METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
};

class C4tAuthClient {
  baseUrl = env.backUrl;

  method = HTTP_METHODS.GET;

  path = '';

  headers = {};

  body;

  parameters = {};

  setMethod(method = HTTP_METHODS.GET) {
    this.method = method;
    return this;
  }

  addHeader(key = '', value = '') {
    this.headers = { ...this.headers, [key]: value };
    return this;
  }

  addHeaders(newHeaders = {}) {
    this.headers = { ...this.headers, ...newHeaders };
    return this;
  }

  addParameter(key = '', value = '') {
    this.parameters = { ...this.parameters, [key]: value };
    return this;
  }

  addParameters(newParameters = {}) {
    this.parameters = { ...this.parameters, ...newParameters };
    return this;
  }

  setBody(body) {
    this.body = body;
    return this;
  }

  setJSONBody(body) {
    this.setBody(JSON.stringify(body));
    this.headers = { ...this.headers, 'Content-Type': 'application/json' };
    return this;
  }

  setPath(path = '') {
    this.path = path;
    return this;
  }

  setToken(token) {
    this.addHeader('Authorization', formatToken(token));
    return this;
  }

  constructor() {
    return this;
  }

  async call() {
    return checkResponseStatus(await fetch(this.buildFetchConfig()));
  }

  buildFetchConfig() {
    let urlParams = new URLSearchParams(this.parameters).toString();
    if (urlParams.length > 0) urlParams = `?${urlParams}`;
    const url = `${this.baseUrl}${this.path}${urlParams}`;
    return new Request(url, {
      headers: this.headers,
      method: this.method,
      body: this.body,
    });
  }
}

export { C4tAuthClient as default, HTTP_METHODS };
